.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg2 {
	position: absolute;
	bottom: 16rem;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		bottom: 0;
		left: 0; } }
.wrap {
	position: relative;
	padding-top: 2.00rem;
	width: 100%;
	background-size: contain;
	background-position: 0 0, 0 100%;
	background-repeat: no-repeat; }
.cover {
	position: relative;
	min-height: 38rem;

	overflow: hidden;
	height: calc(100vh - 2rem);
 }	// height: calc(var(--vh, 1vh) * 100 - 2rem)
.text {
	margin-top: 1.1rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	font-family: Coil;
	width: 14.44rem; }
.img {
	position: relative;
	margin-top: 0;
	width: 100%;
	height: 15.63rem;
	img {
		position: absolute;
		top: -3rem;
		left: -3rem;
		width: 26rem; } }
.text_2 {
	margin-top: -2.6rem; }
.faq {
	margin-top: 5.44rem; }
.faq_text {
	p:nth-child(2) {
		span {
			margin-top: 0.5rem;
			display: block; } } }
.footer_cover {
	margin-top: 3.11rem; }
.socials {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		padding-top: 2.4rem; }
	.img {
		position: relative;
		left: 0;
		top: 0;
		height: 0;
		img {
			top: -21rem;
			left: 37rem;
			width: 39rem; } }
	.cover {
		height: calc(100vh - 2.4rem);
		min-height: 46.88rem; }
	.title {
		width: 53rem; }
	.text {
		margin-top: 2.6rem;
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 28.5rem;
		&_2 {
			width: 41rem; } }
	.socials {
		margin-top: 1.5rem;
		display: block; }
	.progress {
		margin-top: 9.4rem; }
	.faq {
		margin-top: 8.24rem;
		overflow: hidden; }
	.faq_text {
		margin-top: 1rem;
		width: 43.38rem;
		p:nth-child(2) {
			span {
				display: inline; } } }

	.cards {
		margin-top: 3.1rem;
		width: 35.69rem; }
	.footer_cover {
		margin-top: 1.51rem; } }
