.inner {
	position: relative; }

.btext {
	margin-top: 0.2rem;
	font-weight: bold;
	width: 100%;
	transition: opacity ease 0.3s; }
.text {
	width: 20.94rem;
	transition: opacity ease 0.3s; }
.title {
	span {
		font-weight: 500; }
	span:nth-child(1) {
		color: var(--blue); }
	span:nth-child(2) {
		color: var(--yellow); } }
.btns {
	margin-top: 1.2rem;
	display: flex;
 }	// font-weight: 600

.btn {
	display: flex;
	align-items: center;
	justify-content: center;

	transition: color ease 0.5s;
	width: 5.06rem;
	height: 1.44rem;
	border: 0.03rem solid #828282;
	border-radius: 1.25rem;
	flex-shrink: 0;
	margin-right: 0.31rem;
	img {
		width: 3.69rem; } }
.btn_disable {
	pointer-events: none;
	opacity: 0.3;
	color: #C7C7C7; }
.svg {
	margin-top: 0.85rem;
	width: 21.38rem;
	height: auto; }

@media screen and ( min-width: 768px ) {
	.inner {
		padding-top: 7rem;
 }		// overflow: hidden
	.bg {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		width: 100%;
		pointer-events: none;
		z-index: -1;
		bottom: 0;
		// overflow: hidden
		img {
			bottom: -9.7rem;
			position: absolute; } }
	.svg {
		margin-top: 1rem;
		width: 36.38rem; }
	.title {
		font-weight: 600; }
	.info {
		position: absolute;
		top: 2.5rem;
		left: 44rem;
		&_2 {
			margin-top: 7.68rem;
			h2 {
				width: 29.3rem; } } }
	.text {
		margin-top: 0.5rem;
		width: 29.44rem; } }
