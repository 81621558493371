.rows {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start; }
.rows_bottom {
	flex-direction: column-reverse; }
.rows_right {
	.row {
		justify-content: flex-end; } }

.row {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.44rem;

	flex-shrink: 0; }
.circle {
	margin-right: 0.44rem;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: var(--color);
	flex-shrink: 0;
	&:last-child {
		margin-right: 0; } }

@media screen and ( min-width: 768px ) {

	.row {
		margin-bottom: 0.81rem; }
	.circle {
		width: 0.69rem;
		height: 0.69rem;
		margin-right: 0.81rem; } }
