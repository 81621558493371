.slider {
    position: relative;
    width: 100%; }
.body {
    padding: 0;
    position: sticky;
    // height: 100vh
    width: 100%;
    top: 0;
    left: 0; }
