.scroll {
	position: absolute;
	left: 50%;
	height: 0.56rem;
	width: 1.13rem;
	bottom: 2rem;
	transform: translate(-50%);
	z-index: 10;
	img {
		height: 0.56rem;
		width: 1.13rem;
		animation: fade_move_down 2s ease-in-out infinite; } }

@keyframes fade_move_down {
	0% {
		transform: translate(0, -100%);
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		transform: translate(0, 100%);
		opacity: 0; } }


@media screen and ( min-width: 768px ) {
	.scroll_wrap {
		position: absolute;
		height: 100vh;
		width: 100%;
		pointer-events: none;
		top: -2.4rem;
		left: 0; }
	.scroll {
		bottom: 3rem;
		img {
			width: 3.44rem;
			height: 1.69rem; } } }
