.card {
	padding: 1.88rem 0.9rem;
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	width: 100%;
	height: 14.98rem;
	border-radius: 0.19rem;
	border: 0.06rem solid var(--blue);
	box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.11);
	text-decoration: none;
	pointer-events: auto;
	* {
		pointer-events: none; }
	&_risk, &_talk {
		color: var(--white);
		background: var(--blue);
		.title {
			width: 10rem; } }
	&_talk {
		.title {
			width: 9.5rem; } }
	&_risk {
		.img {
			top: 0; } }
	&_talk {
		.body {
			width: 10.75rem; } }
	&_test {
		color: var(--white);
		background: #63A3F9;
		border-color: #63A3F9;
		.body {
			width: 10rem; } }
	&_feel, &_therapy {
		background: var(--white);
		color: var(--black); } }
.img {
	position: absolute;
	right: 0;
	top: 0.69rem;
	width: 11.19rem;
	height: 11.19rem;
	z-index: 0; }

.body {
	position: relative;
	width: 12.19rem;
	z-index: 10; }

.title {
	font-weight: bold;
	width: 9.4rem; }
.text {
	margin-top: 0.25rem; }
.wrap {
	overflow: hidden;
	position: relative;
	left: 50%;
	transform: translate(-50%);
	width: 100vw;
	margin: 2.94rem auto 0; }
.card_small {
	height: 7.25rem;
	width: 18.38rem;
	align-items: center;
	.img {
		width: 5.38rem;
		height: 5.38rem;
		right: 0.75rem;
		top: 50%;
		transform: translateY(-50%); } }
.bg {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		left: -5rem;
		transform: none;
		width: 35.69rem;
		overflow: unset; }
	.bg {
		position: absolute;
		display: block;
		bottom: 0;
		right: 0;
		width: 45.25rem;
		height: 100%;
		mix-blend-mode: color-dodge; }
	.card {
		position: relative;
		height: 100%;
		padding: 1.44rem 2.05rem;
		border-radius: 0.75rem;
		border-width: 0.19rem;
		overflow: hidden;
		transition: transform .3s;
		&_test {
			border: none;
			padding: 2.34rem 2.05rem;
			.body {
				width: 32.31rem; }
			.title {
				white-space: pre-wrap; }
			.img {
				top: 1.88rem;
				width: 9.19rem;
				height: 9.19rem;
				right: 4.44rem; } }
		&_feel {
			.text {
				width: 17rem; } }
		&_therapy {
			.title {
				width: 16rem; }

			.text {
				width: 17rem; } }

		&_talk {
			.body {
				width: 22.75rem; }
			.title {
				width: 20rem; }

			.text {
				width: 20rem; } }
		&_risk {
			.body {
				width: 22.75rem; }
			.title {
				width: 14rem; }
			.text {
				width: 17rem; }
			.img {
				top: 1rem; } }

		&:not(.card_small) {
			&:hover {
				transform: translateY(-0.8rem); } } }
	.title {
		width: 100%;
		font-size: 1.44rem;
		line-height: 2.06rem; }
	.text {
		margin-top: 0.55rem;
		width: 100%;
		font-size: 1.38rem;
		font-weight: normal;
		line-height: 1.88rem; }
	.body {
		width: 23.75rem; }
	.img {
		top: 1.63rem;
		right: 1.63rem;
		width: 14.63rem;
		height: 14.63rem; }
	.card_small {
		width: 35.69rem;
		height: 16.75rem;
		align-items: center;
		.img {
			width: 13.75rem;
			height: 13.63rem;
			right: 2.50rem;
			transform: translateY(-55%); }
		.title {
			width: 13.88rem; }
		&.card_test {
			.img {
				top: 0.88rem;
				transform: none; } }
		&.card_risk {
			.img {
				top: 7.38rem; } }
		&.card_therapy {
			.title {
				width: 14.8rem; } } } }
