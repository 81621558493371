.wrap {
	position: relative;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat; }
.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.inner {
	padding-top: 2rem; }
.text {
	margin-top: 1.64rem;

	line-height: 1.38rem;
	width: 19.13rem; }
.body {
	margin-top: 5.13rem; }
.command_title, .sources_title {
	font-weight: 600;
	text-transform: uppercase; }
.people {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	span {
		&:nth-child(1) {
			color: var(--blue); } } }
.footer {
	margin-top: 6.56rem;
	position: relative;
	z-index: 10; }
.sources {
	margin-top: 3.25rem; }
.source {
	margin-top: 0.5rem;
	margin-bottom: 1.2rem;
	width: 20.00rem; }
.link {
	font-size: 1rem;
	line-height: 1.38rem;
	color: var(--gray); }
@media screen and ( min-width: 768px ) {
	.wrap {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		min-height: 100vh; }
	.text {
		margin-top: 2.34rem;
		width: 45.25rem;
		line-height: 2.06rem; }
	.body {
		margin-top: 3.83rem;
		display: flex;
		font-size: 1.25rem;
		line-height: 1.88rem; }
	.command {

		width: 33.38rem; }
	.sources {
		margin-top: 0;
		margin-left: 4.6rem;
		width: 28.13rem; }
	.source {

		margin-bottom: 1rem;
		width: 100%; }
	.link {
		font: inherit; }
	.command_title, .sources_title {
		font-weight: 500;
		text-transform: none;
		color: var(--blue);
		font-size: 2.31rem;
		line-height: 2.94rem;
		font-family: 'Coil', sans-serif;
		margin-bottom: 1.3rem; }
	.people {
		margin-top: 0;
		margin-bottom: 0.6rem;
		display: block; }
	.footer {
		margin-top: auto; } }
