.wrap {
	position: fixed;
	padding: 1.88rem 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-color: var(--white);
	z-index: 10001;
	display: flex;
	overflow-x: hidden;
	overflow-y: scroll;
	align-items: center;
	justify-content: flex-start;
	color: var(--white);
	-ms-overflow-style: none {
  	scrollbar-width: none; }
	&::-webkit-scrollbar {
		display: none; }

	&_-1 {
		width: 100%; }
	&_0 {
		.img {
			img {
				width: 20.25rem;
				bottom: 0.5rem;
				left: -0.9rem; } } }
	&_1 {
		.img {
			img {
				width: 21.38rem;
				bottom: 0;
				left: -1.2rem; } } }
	&_2 {
		.img {
			img {
				width: 17.06rem;
				bottom: 0;
				left: 1.1rem; } } }
	&_3 {
		.img {
			img {
				width: 17.31rem;
				bottom: 1rem;
				left: 1rem; } } }
	&_4 {
		.img {
			img {
				width: 15.75rem;
				bottom: 1rem;
				left: 1rem; } } }
	&_5 {
		.img {
			img {
				width: 17.94rem;
				bottom: -2.1rem;
				left: 0.7rem; } } }
	&_6 {
		.img {
			img {
				width: 19.81rem;
				bottom: 0.8rem;
				left: 0.2rem; } } }
	&_7 {
		.page {
			padding: 1.69rem 1.75rem 3.21rem 1.75rem; } } }
.bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg_inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: 0;

	mix-blend-mode: color-burn;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.main_wrap {
	padding: 0 0.44rem 0.5rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column; }
.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	padding: 1.69rem 1.25rem 1.41rem;
	top: 3.68rem;
	left: 50%;
	transform: translate(-50%);
	background-color: var(--blue);
	width: 21.94rem;
	height: 38.63rem;
	border-radius: 0.31rem;
	overflow: hidden;
	// &::after
	// 	position: absolute
	// 	content: ''
	// 	top: 0
	// 	left: 0
	// 	width: 100%
	// 	height: 100%
	// 	background-size: cover
	// 	background-position: 0 0
	// 	background-repeat: no-repeat
	// 	background-image: var(--bg)
	// 	mix-blend-mode: color-burn
	// 	z-index: 0
 }	// 	pointer-events: none
.title {
	color: var(--white); }
.main_img {
	position: relative;
	width: 9.69rem;
	margin-left: auto;
	img {
		position: absolute;
		top: -4rem;
		left: -4.9rem;
		width: 16.25rem; } }
.main_desc {
	margin-top: 0.58rem;
	margin-bottom: 1.88rem;
	width: 13.00rem;
	font-size: 1.25rem;
	line-height: 1.63rem; }
.btn {
	margin-top: auto;
	display: flex;
	width: 100%;
	height: 2.81rem;
	font-weight: 600;
	align-items: center;
	justify-content: center;
	border-radius: 0.31rem;
	background: var(--yellow);
	color: var(--black);
	text-decoration: none;
	text-transform: uppercase;

	&_trans {
		border: 0.06rem solid var(--white);
		background: none;
		color: var(--white); } }
.header {
	display: flex;

	font-size: 1.50rem;
	font-weight: 500;
	width: 100%;
	line-height: 1.75rem; }
.final_header {
	font-size: 1.50rem;
	font-weight: 500;
	width: 100%;
	line-height: 1.75rem; }
.title {

	width: 16.06rem;
	font-weight: 500;
	font-family: Coil; }
.final_title {
	margin-top: 0.5rem;
	font-family: Coil;
	width: 15.63rem; }
.final_text {
	margin-top: 1.44rem;
	font-size: 1.25rem;
	line-height: 1.63rem; }
.socials {
	margin-top: auto;
	margin-bottom: 1.75rem;
	width: 100%; }
.counter {
	opacity: 0.5; }
.img {
	margin-top: auto;
	position: relative;
	height: 0;
	width: 100%;
	pointer-events: none;
	img {
		position: absolute;

		pointer-events: none; } }
.final_btns {
	width: 100%;
	.btn {
		margin-top: 0.5rem; } }
.btns {
	position: relative;
	z-index: 10;
	width: 100%; }
.btn_test {
	margin-bottom: 1rem;
	padding: 0 0.63rem;
	height: 3.38rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 0.88rem;
	line-height: 1.13rem;
	border-radius: 0.25rem;
	background: var(--white);
	color: var(--black);
	&:hover {
		background: #FFF4D0; } }
.popup_false {
	.popup_title {
		color: var(--red); }
	.popup_btn {
		background: var(--red); } }
.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100001; }
.popup_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background: var(--white);
	opacity: 0.85; }
.popup_body {
	padding: 1.44rem 1.13rem 1.75rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 25.94rem;
	width: 21.94rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	background: var(--white);
	box-shadow: 0px 1px 15px 4px rgba(0, 0, 0, 0.15);
	border-radius: 0.31rem; }
.popup_title {
	font-family: Coil;
	font-size: 1.50rem;
	line-height: 1.75rem;
	font-weight: 500;
	color: var(--blue); }
.popup_text {
	padding-bottom: 1.88rem;
	margin-top: 0.63rem;
	width: 100%;
	color: var(--black); }
.popup_btn {
	margin-top: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1.13rem;
	height: 3.81rem;
	font-size: 1.25rem;
	color: var(--white);
	background: var(--blue);
	border-radius: 0.38rem; }

@media screen and ( min-width: 768px ) {
	.wrap {
		justify-content: center;
		align-items: center;
		&_0 {
			.title {
				width: 27.56rem; }
			.img {
				img {
					width: 27.75rem;
					bottom: -21.8rem;
					left: 34rem; } } }
		&_1 {
			.title {
				width: 31.88rem; }
			.img {
				img {
					width: 35.48rem;
					bottom: -25.5rem;
					left: 30.2rem; } } }
		&_2 {
			.title {
				width: 38.63rem; }
			.img {
				img {
					width: 27.36rem;
					bottom: -19rem;
					left: 34.9rem; } } }
		&_3 {
			.title {
				width: 42.88rem; }
			.img {
				img {
					width: 25.51rem;
					bottom: -22.1rem;
					left: 34.6rem; } } }
		&_4 {
			.title {
				width: 36.88rem; }
			.img {
				img {
					width: 25.51rem;
					bottom: -22.1rem;
					left: 34.6rem; } } }
		&_5 {
			.title {
				width: 47.19rem; }
			.img {
				img {
					width: 27.11rem;
					bottom: -21.8rem;
					left: 33.6rem; } } }
		&_6 {
			.title {
				width: 32.69rem; }
			.img {
				img {
					width: 27.41rem;
					bottom: -22.5rem;
					left: 34.6rem; } } }
		&_7 {
			.page {
				align-items: flex-start;
				padding: 4rem 2.25rem 7.01rem 7.15rem; } } }
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		min-height: 44.88rem;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
		z-index: -1;
		img {
			position: absolute;
			top: 0;
			left: 0;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto; } }
	.page {
		margin: auto;
		position: relative;
		top: unset;
		left: unset;
		transform: none;
		width: 68.44rem;
		height: 38.63rem;
		padding: 3rem 3.29rem 4.15rem;
		overflow: hidden; }
	.main_wrap {
		padding: 0 3.84rem;
		justify-content: center;
		.btn {
			margin-top: 2.69rem;
			margin-bottom: 0.7rem;
			width: 18.56rem;
			height: 2.81rem; } }
	.main_title {
		font-size: 3.31rem;
		line-height: 3.50rem;
		width: 31.31rem; }
	.main_desc {
		margin-top: 0.75rem;
		margin-bottom: 0;
		font-size: 1.44rem;
		line-height: 1.88rem; }
	.main_img {
		img {
			top: -21.6rem;
			left: -20.5rem;
			width: 38.25rem; } }
	.header {
		justify-content: space-between; }
	.title {
		width: 42.88rem;
		font-size: 2.88rem;
		line-height: 3.19rem;
		font-weight: bold; }
	.counter {
		margin-top: 0.2rem;
		margin-right: 1.9rem;
		font-size: 2.00rem;
		font-weight: 500;
		line-height: 2.63rem; }
	.img {
		margin-top: 0; }
	.btns {
		margin-top: auto; }
	.btn {
		cursor: pointer; }
	.btn_test {
		margin-bottom: 1.2rem;
		width: 30.00rem;
		text-align: center;
		justify-content: center;
		cursor: pointer;
		font-weight: normal;
		&:hover {
			background: var(--yellow); } }
	.popup_body {
		padding: 3.06rem 4.06rem 3.00rem;

		min-height: 21.06rem;
		width: 44.06rem; }
	.popup_text {
		font-size: 1rem;
		line-height:  1.38rem;
		width: 35.26rem; }
	.popup_btn {
		min-width: 8.13rem;
		height: 3.13rem;
		cursor: pointer; }
	.final_header {
		font-size: 2rem;
		line-height: 2.31rem; }
	.final_title {
		font-size: 3.31rem;
		line-height: 3.5rem;
		width: 38.19rem; }
	.final_text {
		margin-top: 1.24rem;
		width: 33.94rem;
		font-size: 1.44rem;
		line-height: 1.88rem; }
	.final_btns {
		display: flex;
		align-items: center;
		.btn {
			width: 18.56rem;
			margin-right: 1.19rem;
			font-size: 1.25rem;
			transition: all ease 0.3s;
			&:hover {
				background: var(--white);
				color: var(--black); } } } }
