@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,700&display=swap');
html {
	font-size: 4.255vw;
	width: 100%;
	height: 100%; }
body {
	font-size: 1rem;
	line-height: 1.38rem;
	font-weight: 300;
	width: 100%;
	font-family: 'Exo 2', sans-serif;
	-webkit-font-smoothing: antialiased;
	transition: opacity  0.2s; }
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }

 :root {
	--black: #000000;
	--white: #FFFFFF;
	--blue-light: #ACCDFA;
	--blue: #2F80ED;
	--gray6: #F2F2F2;
	--gray3: #828282;
	--gray: #6B6B6B;
	--red: #FD4B2C;
	--yellow: #FECF33; }

.container {
	position: relative;
	max-width: 20.38rem;
	width: 100%;
	margin: 0 auto; }

h1,h2,h3 {
	font-family: 'Coil', sans-serif; }
h1 {
	font-size: 2.88rem;
	line-height: 3.19rem; }

h2 {
	font-size: 1.50rem;
	line-height: 1.75rem;
	font-weight: 500;
	margin-bottom: 0.63rem;
	color: var(--blue); }
h3 {
	font-weight: 300;
	font-size: 1.25rem;
	line-height: 1.63rem;
	color: var(--blue); }
img {
	width: 100%; }
p+p {
	margin-top: 0.5rem; }
button, a, input, textarea {

	font-family: 'Exo 2', sans-serif; }
.wrap {
 }	// overflow: hidden
.swiper-slide {
	width: auto !important;
	pointer-events: none; }
.swiper-container {
	overflow: unset; }
[data-aos] {
	position: relative; }
@media screen and ( min-width: 768px ) {
	html {
		font-size:  1.111vw;
		font-size: clamp(9px, 1.11111vw, 20px);
		text-size-adjust: 100%; }
	body {
		font-size: 1.44rem;
		line-height: 2.06rem; }
	.container {
		max-width: 71.88rem; }
	h1 {
		font-weight: 600;
		font-size: 5.50rem;
		line-height: 5.63rem; }
	h2 {
		font-size: 2.31rem;
		line-height: 2.94rem;
		max-width: 34.38rem;
		width: 100%;
		margin-bottom: 0.93rem; }
	p+p {
		margin-top: 0.9rem; } }
