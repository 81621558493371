.wrap {
	margin-top: 2.50rem;
	// height: 21.25rem
	background: var(--yellow); }
.inner {
	padding-top: 2.05rem;
	padding-bottom: 3.25rem;
	margin: 0 auto;
	max-width: 17.26rem;
	width: 100%; }
.text {
	width: 100%; }
.socials {
	margin-top: 0.94rem; }

@media screen and ( min-width: 768px ) {
	.wrap {
		position: relative;
		margin-top: 6.88rem;
		height: 21.13rem; }
	.inner {
		padding-bottom: 4rem;
		margin: 0 auto;
		display: flex;
		width: 100%;
		padding-top: 3.36rem;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		max-width: 82.50rem; }
	.socials {
		position: relative;
		margin-top: 1.4rem;
		z-index: 10; }
	.header {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
		width: 26.25rem;
		margin-right: 9.3rem; }

	.logo_info {
		width: 7.19rem; }
	.logo_aids {
		width: 7.69rem;
		margin-left: 1.58rem;
		margin-top: -0.8rem; }
	.body {

		margin-top: -0.4rem;
		width: 34rem; }
	.text {
		b {
			display: block; } }

	.scroll {
		position: absolute;
		bottom: 3.1rem;
		left: 50%;
		transform: translate(-72.6%); } }
