.wrap {
	margin-top: 4.56rem;
	background: var(--yellow); }
.inner {
	padding-top: 2.63rem;
	padding-bottom: 4rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 19.81rem;
	width: 100%; }
.header {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: flex-start; }

.logo_info {
	width: 7.88rem; }
.logo_aids {
	width: 8.38rem;
	margin-top: 1rem;
 }	// mix-blend-mode: multiply

.body {
	width: 100%; }

.socials {
	width: 100%;
	margin-top: 2.38rem; }
.bg {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		position: relative;
		margin-top: 8.08rem;
		height: 14.19rem; }
	.inner {
		padding-top: 3.16rem;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 82.50rem; }
	.socials {
		position: relative;
		margin-top: 0;
		z-index: 10; }
	.header {
		justify-content: flex-start;
		flex-direction: row;
		width: 26.25rem;
		margin-right: 18.7rem; }

	.logo_info {
		width: 7.19rem; }
	.logo_aids {
		width: 7.69rem;
		margin-left: 1.58rem;
		margin-top: -0.8rem; }
	.body {

		margin-top: 0;
		width: 11.19rem; } }
