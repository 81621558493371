.inner {
	padding-top: 1.75rem;

	position: relative;
	// &::after
	// 	position: absolute
	// 	content: ''
	// 	left: 50%
	// 	transform: translate(-50%)
	// 	top: 0
	// 	width: 100vw
	// 	height: 52.19rem
	// 	background-image: var(--bg)
	// 	background-repeat: no-repeat
	// 	background-position: 50% 50%
	// 	background-size: contain
 }	// 	z-index: -1
.bg1 {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%);
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.title {
	transition: opacity ease 0.5s; }
.text {
	transition: all ease 0.3s; }
.date {
	transition: opacity ease 0.5s; }
.stat {
	display: flex;
	width: 100%; }
.stat_path {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start; }
.stat_path_left {
	width: 66%;
	color: var(--blue);
	.stat_bar {
		background: var(--blue);
		&::after {
			background: linear-gradient(180deg, #2F80ED 0%, rgba(47, 128, 237, 0) 100%); } } }
.stat_path_right {
	width: 34%;
	color: var(--red);
	.stat_bar {
		background: var(--red);
		&::after {
			background: linear-gradient(180deg, #FD4B2C 0%, rgba(253, 75, 44, 0) 100%); } } }
.stat_bar {
	position: relative;
	height: 0.94rem;
	width: 100%;
	&::after {
		position: absolute;
		content: '';
		top: 0.94rem;
		left: 0;
		width: 100%;
		height: 5.50rem;
		opacity: 0.1; } }
.stat_number {
	margin-top: 2.88rem;
	font-size: 1.25rem; }
.stat_text {
	margin-top: 0.38rem;
	color: var(--black);
	max-width: 8.88rem; }
.text_blue {
	margin-top: 1.94rem;
	font-size: 1.25rem;
	width: 19rem;
	line-height: 1.63rem;
	color: var(--blue); }
.stage {
	margin-top: 1.25rem;
	position: relative;
	// height: 28.81rem
	// height: 70vh
	height: 24.5rem;
	width: 100%; }


.stage_title {
	font-weight: 600; }

.bar {
	margin-top: 0.5rem;
	height: 0.13rem;
	width: 100%;
	background: var(--gray6);
	div {
		display: flex;
		width: 0%;
		height: 100%;
		overflow: hidden;
		span:nth-child(1) {
			display: block;
			width: 13.63rem;
			height: 100%;
			background: var(--blue);
			flex-shrink: 0; }
		span:nth-child(2) {
			display: block;
			width: 6.56rem;
			height: 100%;
			background: var(--red);
			flex-shrink: 0; } } }

.date {
	margin-top: 0.2rem;
	color: var(--gray3); }
.body {
	display: flex;
	position: relative; }

.legend {
	position: absolute;
	top: 2rem;
	left: 9.2rem;
	width: 9.25rem;
	height: 1.38rem;
	display: flex;
	justify-content: space-between;
	span {
		width: 3.75rem;
		flex-shrink: 0;
		font-size: 0.63rem;
		line-height: 0.69rem;
		position: relative;
		color: var(--blue);
		&::after {
			position: absolute;
			content: '';
			top: 1.6rem;
			left: 0.2rem;
			height: 0.63rem;
			width: 0.06rem;
			background: var(--blue); }
		&:nth-child(2) {
			color: var(--gray3);
			text-align: right;
			&::after {
				background: var(--gray3);
				left: 3.6rem; } } } }

.img {
	position: relative;
	width: 9.25rem;
	height: 12.13rem;
	img {
		position: absolute;
		top: 0;
		left: -1.8rem;
		width: 13.3rem; } }
.circles {
	margin-left: 0;
	margin-top: 4.63rem;
	width: 9.31rem; }
.stage_text {
	margin-left: 3.44rem;
	margin-top: 0.55rem;
	width: 16.94rem;
	transition: all ease 0.3s;
	span {
		color: var(--blue); } }
.scroll {
	position: absolute;
	bottom: -1.1rem;
	left: 50%;
	transform: translate(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 3.31rem;
	span {
		text-align: center;
		margin-bottom: 0.56rem;
		font-size: 0.88rem;
		line-height: 1.00rem;
		color: var(--gray3); }
	img {
		width: 1.13rem;
		height: 0.56rem;
		flex-shrink: 0;
		animation: fade_move_down 2s ease-in-out infinite; } }
@keyframes fade_move_down {
	0% {
		transform: translate(0, -100%);
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		transform: translate(0, 100%);
		opacity: 0; } }

@media screen and ( min-width: 768px ) {
	.inner {
		padding-top: 3.75rem; }
	.bg1 {
		top: unset;
		bottom: 0;
		img {
			top: unset;
			bottom: -9.7rem; } }
	.title {
		width: 33.38rem;
		&_hide {
			opacity: 0 !important; } }
	.text {
		width: 42.63rem;
		&_hide {
			opacity: 0 !important; } }
	.stage_body {
		position: relative;
		transition: all ease 0.5s;
		top: 0;
		&_active {
			top: -6.5rem; } }
	.stage {
		margin-top: 1.95rem;

		height: 50vh; }
	.stage_title {
		position: absolute;
		top: 8.9rem;
		left: 44.5rem; }
	.stage_text {
		position: absolute;
		top: 11.6rem;
		left: 44.4rem;
		width: 29.25rem;
		font-size: 1.44rem;
		line-height: 2.06rem;
		margin-top: 0;
		margin-left: 0; }
	.stat {
		width: 41.13rem; }
	.stat_bar {
		height: 1.56rem; }
	.stat_number {
		margin-top: 4.68rem;
		font-size: 1.38rem;
		font-weight: bold; }
	.stat_text {
		margin-top: 0.4rem;
		font-size: 1.13rem;
		line-height: 1.75rem;
		max-width: 10.88rem; }
	.text_blue {
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 44rem; }
	.scroll {
		bottom: -3rem; }
	.bar {
		width: 41.00rem;
		div {
			span:nth-child(1) {
				width: 26.63rem; }
			span:nth-child(2) {
				width: 14.37rem; } } }
	.body {
		flex-direction: row-reverse;
		justify-content: flex-end;
		width: 35.88rem; }
	.date {
		margin-top: 0.6rem; }
	.circles {
		margin-top: 6.43rem;
		margin-left: 0.5rem;
		width: 23.15rem;
		margin-right: 2.88rem; }
	.img {
		img {
			top: -4.3rem;
			left: -7.5rem;
			width: 25.3rem; } }
	.legend {
		top: 4rem;
		left: 0.6rem;
		width: 17.75rem;
		span {
			width: 4.65rem;
			font-size: 0.8rem;
			line-height: 0.8rem;
			&::after {
				width: 1px; } } } }
