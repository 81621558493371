.wrap {
	padding-top: 2.00rem;
	width: 100%;
	position: relative; }
.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg2 {
	position: absolute;
	bottom: 16rem;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		bottom: 0;
		left: 0; } }
.cover {
	position: relative;
	// min-height: 42.31rem
	overflow: hidden;
	min-height: 38rem;
	height: calc(100vh - 2rem);
 }	// height: calc(var(--vh, 1vh) * 100 - 2rem)
.subtitle {
	margin-top: 1rem;
	width: 15.13rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	font-family: Coil; }
.img {
	position: relative;
	width: 100%;
	height: 14.75rem;
	img {
		position: absolute;
		top: 0;
		left: -1.3rem;
		width: 22.6rem; } }
.info {
	margin-top: 1rem; }
.info_2 {
	margin-top: -7.5rem; }
.info_3 {
	margin-top: 3.9rem; }
.fact {
	margin-top: 2.69rem;
	margin-left: 3.44rem; }
.fact_number {
	font-size: 2.19rem;
	font-weight: bold;
	color: var(--blue); }
.fact_text {
	margin-top: 0.63rem;
	margin-bottom: 5.69rem; }

.progress {
	margin-top: -1.8rem; }
.lead {
	margin-top: 1.01rem;
	font-family: Coil;
	margin-bottom: 3.81rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	color: var(--blue);
	font-weight: 300; }
.illustration {
	position: relative;
	height: 30.06rem;
	width: 100%;
	img {
		width: 100vw;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); } }
.footer_cover {
	margin-top: 1.81rem; }

.desc {
	margin-top: 0.5rem;
	font-size: 0.69rem;
	line-height: 0.94rem;
	color: var(--gray); }
.socials {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		padding-top: 2.4rem; }
	.img {
		position: relative;
		left: 0;
		top: 0;
		height: 0;
		img {
			top: -24.6rem;
			left: 31.4rem;
			width: 46rem; } }
	.title {
		width: 54rem; }
	.cover {
		height: calc(100vh - 2.4rem);
		min-height: 46.88rem;
		overflow: unset; }
	.subtitle {
		margin-top: 1.9rem;
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 33.5rem; }
	.socials {
		margin-top: 2.7rem;
		display: block; }
	.info {
		margin-top: 11.5rem;
		&_2 {
			margin-top: -1.3rem; }
		&_3 {
			margin-top: 8.9rem; } }
	.text {
		&_1 {
			margin-top: 1rem;
			width: 43rem;
			p {

				display: inline; }
			p+p {
				margin-top: 0; } }
		&_2 {
			margin-top: 1rem;
			width: 42rem; }
		&_3 {
			margin-top: 1rem;
			width: 42rem; }
		&_4 {
			margin-top: 1rem;
			width: 42rem; } }
	.lead {
		margin-top: 1.01rem;
		margin-bottom: 7.7rem;
		font-size: 2.31rem;
		width: 41rem;
		line-height: 2.94rem;
		font-family: 'Exo 2', sans-serif; }
	.desc {
		margin-top: 1rem;
		margin-left: 0.6rem;
		position: relative;
		font-size: 1.13rem;
		line-height: 1.25rem;
		width: 35rem;
		span {
			position: absolute;
			left: -1rem;
			top: 0; } }
	.footer_cover {
		margin-top: 3.61rem; }
	.faq {
		overflow: hidden;
 }		// margin-top: -2.4rem
	.cards {
		width: 35.69rem; } }
