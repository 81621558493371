.placeholder {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000000;
	background: var(--white); }
.placeholder_bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	img {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0; } }
.placeholder_inner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 78.56rem;
	height: 36.00rem; }

.placeholder_header {
	display: flex;
	justify-content: space-between;
	width: 98%;
	h1 {
		font-size: 5.25rem;
		line-height: 5.06rem;
		span {
			color: var(--red); } } }
.placeholder_logo {
	margin-top: 0.8rem;
	width: 16.25rem; }
.placeholder_body {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-top: -9.1rem; }
.placeholder_qr {
	display: flex;
	font-family: 'Coil';
	img {
		width: 8.75rem;
		height: 8.75rem;
		margin-right: 1.50rem; } }
.placeholder_img {
	position: relative;
	width: 31.88rem;
	height: 25.56rem;
	img {
		position: absolute;
		width: 51.38rem;
		right: 0;
		bottom: -5.5rem; } }
