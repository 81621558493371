.wrap {
	padding-top: 2.00rem;
	width: 100%;
	position: relative; }
.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg2 {
	position: absolute;
	bottom: 16rem;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		bottom: 0;
		left: 0; } }
.cover {
	position: relative;
	height: calc(100vh - 2rem);
	min-height: 38rem;
	// height: calc(var(--vh, 1vh) * 100 - 2rem)
	overflow: hidden; }
.subtitle {
	margin-top: 1rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	font-family: Coil;
	width: 15.13rem; }
.img {
	position: relative;
	width: 100%;
	height: 14.75rem;
	img {
		position: absolute;
		top: 1.1rem;
		left: -0.4rem;
		width: 22.6rem; } }
.info {

	margin-top: 1rem; }
.info_2 {

	margin-top: 1rem; }
.info_3 {

	margin-top: 1rem; }
.text {
	&_2 {
		margin-top: 3.24rem; }
	&_3 {
		width: 19rem; } }
.gif {
	margin-top: 1.6rem; }
.gif_active {
	.gif_subbar_red {
		width: 35%; }
	.gif_subbar_blue {
		width: 65%; }
	.gif_subbar_hidden {
		width: 100%; } }
.gif_number {
	margin-top: 0.3rem; }
.gif_bar {
	margin-top: 0.31rem;
	width: 20.4rem;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center; }
.gif_subbar {
	display: block;
	height: 0.94rem; }
.gif_subbar_red {
	width: 95%;
	background: var(--red);
	transition: width linear 2s; }
.gif_subbar_blue {
	width: 5%;
	background: var(--blue);
	transition: width linear 2s; }
.bar_text {
	margin-top: 0.5rem;
	width: 18.94rem;
	span {
		&:nth-child(1) {
			color: var(--red); }
		&:nth-child(2) {
			color: var(--blue); } } }
.lead {
	margin-top: 0.81rem;
	margin-bottom: 1.14rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	font-family: Coil;
	color: var(--blue);
	font-weight: 300; }

.stats {
	margin-top: 1.4rem;
	margin-left: 3.18rem;
	margin-bottom: 1.1rem;
	position: relative;
	width: 12.88rem;
	height: 12.88rem;
	&::after {
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		height: 4.50rem;
		width: 0.06rem;
		background: var(--blue);
		transform-origin: 0% 9.35rem;
		transform: translateY(-9.35rem ) rotate( 215deg); }
	&_img {
		display: none; } }
.svg {
	transform: rotate(230deg);
	width: 12.88rem;
	height: auto; }
.svg_circle {
	stroke-dasharray: 502.4;
	stroke-dashoffset: 146.5;
	transition: stroke-dashoffset ease 0.5s; }

.fact {
	margin-left: 3.48rem;
	margin-top: 1rem;
	width: 15.94rem;
	&_2 {
		margin-top: 0.7rem;
		margin-left: 2.98rem;
		.fact_number {
			span {
				display: none; } } }
	&_img {
		width: 14.13rem;
		margin-bottom: -0.21rem;
		margin-left: -0.71rem; }
	&_number {
		font-size: 1.25rem;
		line-height: 1.63rem;
		color: var(--blue);
		font-weight: bold; } }
.quote {
	position: relative;
	margin-top: 1.38rem;
	margin-bottom: 4.00rem;
	padding-left: 3.35rem;
	&_2 {
		margin-top: 1.50rem;
		margin-bottom: 1.9rem;
		.quote_name {
			margin-top: -1.74rem; } }
	&_icon {
		position: absolute;
		top: 0.3rem;
		left: 0.2rem;
		width: 1.56rem;
		height: 1.06rem; }
	&_text {
		font-style: italic;
		width: 15.44rem;
		font-size: 0.88rem;
		line-height: 1.25rem; }
	&_img {
		margin-left: -3.8rem;
		margin-top: 1.41rem;
		margin-bottom: 2.1rem;
		width: 20.33rem; }
	&_name {
		margin-top: 1.56rem; }
	&_status {
		margin-top: 0.3rem;
		width: 10.44rem;
		line-height: 1.13rem;
		color: var(--gray3); } }

.illustration {
	width: 100vw;
	margin: 0 -1.65rem 2.09rem;
	pointer-events: none; }
.socials {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		padding-top: 2.4rem; }
	.img {
		position: relative;
		left: 0;
		top: 0;
		height: 0;
		img {
			top: -17rem;
			left: 40rem;
			width: 33rem; } }
	.cover {
		height: calc(100vh - 2.4rem);
		min-height: 46.88rem; }
	.title {
		width: 52.00rem; }
	.img {
		width: 100%;
		position: relative;
		left: 0;
		top: 0;
		height: 0;
		img {
			top: -14rem;
			left: 32.1rem;
			width: 42rem; } }
	.subtitle {
		margin-top: 1.91rem;
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 27.1rem; }
	.socials {
		display: block;
		margin-top: 3.49rem; }
	.info {

		margin-top: 11.49rem;
		h2 {
			max-width: 44.38rem; }
		&_2 {
			margin-top: 8.5rem;
			h2 {
				max-width: 31.38rem;
				margin-bottom: 0.83rem; }
			.text {
				max-width: 43.16rem; } }
		&_3 {
			margin-top: 10.8rem;
			h2 {
				max-width: 33.98rem; } } }
	.text {
		max-width: 35.06rem;
		&_2 {
			margin-top: 3.08rem;
			width: 41.88rem; }
		&_3 {
			width: 47.94rem; } }
	.gif {
		margin-top: 0.74rem;
		width: 35.19rem;
		&_number {
			font-size: 1.56rem; }

		&_bar {
			margin-top: 0;
			height: 1.50rem;
			width: 35.19rem; }
		&_subbar {
			height: 1.50rem; } }
	.bar_text {
		margin-top: 1.3rem;
		width: 37.38rem; }
	.lead {
		margin-top: 1.18rem;
		margin-bottom: 5.43rem;
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 43.50rem;
		font-family: 'Exo 2', sans-serif; }
	.stats_wrap {
		display: flex;

 }		// display: none
	.stats {

		margin-top: 2.55rem;
		margin-left: -1.2rem;
		margin-bottom: 0;
		width: 15.08rem;
		height: auto;
		// &_img
		// 	display: block
		// 	width: 61.38rem
		&::after {
			transform: none;
			top: 0.6rem;
			left: 7.5rem;
			width: 8.94rem;
			height: 0.13rem; } }
	.svg {
		transform: rotate(270deg);
		width: 15.08rem; }
	.fact {
		// display: none
		margin-left: 2.48rem;
		margin-top: 2.8rem;
		width: 22.94rem;
		&_2 {
			margin-top: 1.55rem;
			margin-left: 0;
			.fact_text {
				width: 20rem; }
			.fact_number {
				margin-top: 0.3rem;
				span {
					display: inline; } } }
		&_img {
			width: 29.19rem;
			margin-left: -1.31rem;
			margin-bottom: 0; }
		&_number {
			margin-top: -0.5rem;
			font-size: 1.69rem;
			line-height: 2.06rem;
			font-weight: 600; }
		&_text {
			font-size: 1.44rem;
			line-height: 2.06rem;
			color: var(--gray); } }
	.quote {
		margin-top: 2.3rem;
		padding-left: 0;
		&_2 {
			margin-top: 1.65rem;
			margin-bottom: 5.5rem;
			.quote_text {
				font-size: 1.44rem;
				line-height: 2.06rem;
				width: 50.88rem; } }
		&_img {
			margin-top: 2.38rem;
			margin-left: -0.9rem;
			margin-bottom: 3.05rem;
			width: 38.44rem; }
		&_text {
			width: 49.31rem;
			font-size: 1.44rem;
			line-height: 2.06rem; }
		&_name {
			margin-top: 2.76rem; }
		&_status {
			width: 24.69rem; }
		&_icon {
			left: -4.35rem;
			width: 2.5rem;
			height: 1.69rem; } }
	.illustration {
		margin: 1rem 0 0;
		max-width: 59.88rem;
		width: 100%; }

	.faq {
		overflow: hidden;
 }		// margin-top: -2.4rem
	.cards {
		width: 35.69rem; } }
