.wrap {
	margin-top: 2.1rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.31rem;
	line-height: 1.88;
	cursor: pointer; }
.svg {
	position: relative;
	margin-right: 0.44rem;
	flex-shrink: 0;
	width: 1.88rem;
	height: 1.88rem;
	svg {
		width: 100%;
		height: auto;
		path {
			stroke: var(--black); }
 } }		// height: 1.13rem
.svg_second {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: all ease 0.3s; }
.wrap_blue {

	color: var(--blue);
	svg {
		path {
			stroke: var(--blue); } } }

@media screen and ( min-width: 768px ) {
	.wrap {
		margin: 0;
		font-size: 1.31rem;
		line-height: 1.88rem;
		transition: all ease 0.3s;
		svg {
			// margin-right: 0.4rem
			// width: 2.08rem
			// height: 2.08rem
			transition: all ease 0.3s;
			path {
				transition: all ease 0.3s;
				&:nth-child(1) {
					fill: none; } } }
		&:hover {
			color: var(--white);
			svg {
				opacity: 0; }
			.svg_second {
				opacity: 1; } } } }

