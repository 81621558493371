.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	width: 100%; }

.inner {
	position: relative;
	width: 100%; }

.burger {
	position: absolute;
	right: -0.4rem;
	top: 1.19rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.81rem;
	height: 1.81rem;
	background: var(--blue);
	img {
		width: 1rem;
		flex-shrink: 0; } }

.close {
	position: absolute;
	right: 1.19rem;
	top: 1.19rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.56rem;
	height: 1.56rem;
	img {
		width: 1.06rem;
		height: 1.06rem;
		flex-shrink: 0; } }
.menu {
	padding: 0 2.05rem 3.8rem;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	top: 0;
	right: -100%;
	z-index: 10000;
	width: 100%;
	height: 100%;
	transition: transform ease 0.5s;
	transform: translate(0);
	pointer-events: none;
	background: var(--blue); }
.menu_open {
	transform: translate(-100%);
	pointer-events: auto; }

.list {
	position: relative;
	padding-top: 5.49rem;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start; }
.link {
	margin-bottom: 1.9rem;
	text-decoration: none;
	font-weight: normal;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: var(--white);
	&:last-child {
		margin-bottom: 0; } }
.socials {
	width: 100%;
	margin-top: auto; }
.langs {
	position: absolute;
	top: 1.6rem;
	left: 0;
	display: flex; }
.lang {
	position: relative;
	margin-right: 0.6rem;
	font-size: 1.25rem;
	line-height: 1.50rem;
	text-decoration: none;
	font-weight: normal;
	color: var(--white);
	&:nth-child(1) {
		&::after {
			// position: absolute
			content: '|';
			margin-left: 0.6rem;
			color: var(--white); } }
	&_active {
		color: var(--yellow); } }

@media screen and ( min-width: 768px ) {
	.menu {
		align-items: flex-start;
		padding: 0 5.15rem;
		width: 44.06rem;
		right: -44.06rem; }
	.burger {
		top: 3.00rem;
		right: -5.25rem;
		width: 3.5rem;
		height: 3.5rem;
		cursor: pointer;
		img {
			width: 1.88rem; } }
	.close {
		top: 3.00rem;
		right: 3.75rem;
		width: 3.50rem;
		height: 3.50rem;
		cursor: pointer;
		img {
			width: 1.69rem;
			height: 1.69rem; } }
	.list {
		padding: 4.25rem 0;
		width: 29.69rem;
		overflow: scroll;
		&::-webkit-scrollbar {
			width: 0;
			height: 0;
			background: transparent; }
		&::-ms-overflow-style {
			width: 0;
			height: 0;
			background: transparent; } }

	.link {
		margin-bottom: 0.69rem;
		padding: 1.13rem 1.31rem;
		position: relative;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		height: 3.44rem;
		width: 100%;
		font-size: 1rem;
		line-height: 1.19rem;
		border-radius: 0.31rem;
		border: 0.06rem solid var(--white);
		flex-shrink: 0;
		text-decoration: none;
		text-align: left;

		&_card {
			height: 6.81rem;
			background: var(--white);
			color: var(--black); }
		&_current, &:hover {
			background: #63A3F9;
			color: var(--white);
			border-color: #63A3F9; } }
	.body {
		width: 18.38rem; }
	.title {
		font-weight: bold;
		margin-bottom: 0.31rem; }
	.img {
		position: absolute;
		top: 0.69rem;
		right: 2rem;
		width: 5.44rem;
		height: 5.44rem; }
	.socials {
		margin-top: 3.75rem;
 }		// display: none
	.lang {
		&::after {
			display: none; }
		&_active {
			text-decoration: underline; } } }
