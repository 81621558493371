.wrap {
	position: relative;
	width: 100vw;
	height: 100vh;
	min-height: 42.31rem;
	overflow: hidden;
 }	// height: 33.5rem
.inner {
	position: relative;
	// max-width: 20.38rem
	// height: 100%
	width: 100%;
	margin: 0 auto; }
.items {
	position: absolute;
	display: flex;
	width: max-content;
	// top: 3rem
	height: 33.5rem;
	transition: left ease; }
.inner {
	position: relative; }
.item {
	padding: 3.63rem 1.69rem 0.63rem;
	flex-shrink: 0;
	width: 100vw; }
.img {
	position: relative;
	width: 6.69rem;
	height: 6.69rem;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 10.63rem;
		flex-shrink: 0; } }
.title {
	margin-top: 0.8rem;
	margin-bottom: 0.5rem;
	font-weight: bold; }

@media screen and ( min-width: 768px ) {
	.wrap {
		padding-top: 5.1rem;
		height: 43.44rem;
		width: 100%; }
	.inner {
		position: relative;
		max-width: 71.88rem;
		height: 43.44rem;
		width: 100%;
		margin: 0 auto; }
	.items {
		height: 38.34rem; }
	.item {
		padding: 0;
		margin-right: 3.81rem;
		width: 33.13rem;
		&:nth-child(1) {
			.text {
				width: 33.13rem; } }
		&:last-child {
			margin-right: 1.8rem; } } }
