.wrap {
	position: relative;
	padding-top: 2.00rem;
	width: 100%; }
.container {
	position: relative;
	max-width: 20.38rem;
	width: 100%;
	margin: 0 auto; }
.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg2 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		bottom: 0;
		left: 0; } }
.cover {
	position: relative;
	width: 100%;
	overflow: hidden;
	min-height: 38rem;
	height: calc(100vh - 2rem);
 }	// height: calc(var(--vh, 1vh) * 100 - 2rem)
.title {

	span {
		display: block;
		font: inherit;
		color: var(--red); } }
.img_wrap {
	position: relative;
	margin-top: 0.69rem;
	width: 100%;
	height: 25.00rem; }
.img {
	position: absolute;
	&_1 {
		width: 12rem;
		top: 0rem;
		left: -1.8rem; }
	&_2 {
		width: 14.1rem;
		top: -1.2rem;
		left: 8.2rem; }
	&_3 {
		width: 16.1rem;
		top: 8.3rem;
		left: -3.8rem; }
	&_4 {
		width: 15rem;
		top: 10.1rem;
		left: 8rem; } }
.facts {
	margin-top: 3.04rem; }

.faq {
	margin-top: 12.56rem; }
.faq_title {
	color: var(--black);
	font-weight: 500;
	font-size: 1.50rem;
	line-height: 1.75rem;
	span {
		color: var(--red);
		font: inherit; } }
.faq_wrap {
	margin: 1rem -0.7rem 0;
	width: 21.69rem;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 1.25rem; }
@media screen and ( min-width: 768px ) {
	.container {
		max-width: 82.50rem; }
	.bg1 {
		height: 100%;
		overflow: hidden; }
	.cover {
		min-height: 46.88rem; }
	.title {
		max-width: 50.44rem;
		font-size: 5.63rem;
		line-height: 6.00rem; }
	.img {
		&_1 {
			width: 27.5rem;
			top: -2rem;
			left: -5.3rem; }
		&_2 {
			width: 33.69rem;
			top: -5.2rem;
			left: 11.2rem; }
		&_3 {
			width: 38.75rem;
			top: -11.5rem;
			left: 29.2rem; }
		&_4 {
			width: 32rem;
			top: -3.9rem;
			left: 54rem; } }
	.facts {
		margin-top: 10.04rem; }
	.faq {
		margin-top: 24.19rem; }
	.faq_title {
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 65.06rem;
		max-width: 100%; }
	.faq_wrap {
		margin: 2.06rem 0 0;
		width: 100%;
		justify-items: stretch;
		grid-gap: 1.56rem;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 19.00rem 19.00rem 13.38rem;
		&>:last-child {
			grid-column-start: 1;
			grid-column-end: 3; }

		&>:nth-child(3) {
			color: var(--white);
			background: var(--blue); }
		&>:nth-child(4) {
			color: var(--black);
			background: var(--white); } } }
