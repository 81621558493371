.wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%; }

.link {
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.25rem;
	height: 2.25rem;
	text-decoration: none;
	outline: none;
	border-radius: 50%;
	flex-shrink: 0;
	cursor: pointer;
	img {
		width: auto;
		height: 100%; } }
.link_copy {
	img {
		width: 1.50rem; } }
.img_hover {
	opacity: 0;
	width: 100%; }
.copy {
	padding: 0.2rem 0.7rem;
	background: var(--white);
	position: fixed;
	bottom: 2rem;
	left: 50%;
	transform: translate(-50%);
	transition: all ease 0.5s;
	opacity: 0;
	&_show {
		opacity: 1; } }

@media screen and ( min-width: 768px ) {
	.link {
		margin-right: 0.94rem;
		position: relative;
		flex-shrink: 0;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all ease 0.3s; }
		&:hover {
			img {
				opacity: 0; }
			.img_hover {
				opacity: 1; } } } }
