.wrap {
	padding-top: 2.00rem;
	width: 100%;
	position: relative; }
.bg1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		top: 0;
		left: 0; } }
.bg2 {
	position: absolute;
	bottom: 16rem;
	left: 0;
	width: 100%;
	pointer-events: none;
	z-index: -1;
	img {
		position: absolute;
		bottom: 0;
		left: 0; } }
.cover {
	position: relative;
	// height: 100vh
	min-height: 38rem;
	height: calc(100vh - 2rem);
 }	// height: calc(var(--vh, 1vh) * 100 - 2rem)
.text {
	margin-top: 1rem;
	width: 15.13rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	font-family: Coil; }
.info {
	margin-top: 1rem; }
.info_2 {
	margin-top: 3.88rem;
	h2 {
		width: 19.3rem; } }
.img {
	position: relative;
	width: 100%;
	height: 14.75rem;
	img {
		position: absolute;
		top: -3rem;
		left: -4.7rem;
		width: 26.6rem; } }
.fact {
	margin-top: 1.59rem;
	margin-left: 3.44rem;
	margin-bottom: 3.89rem;
	width: 14rem; }
.fact_number {
	font-size: 2.19rem;
	font-weight: bold;
	letter-spacing: 0.05em;
	font-family: Coil;
	color: var(--blue); }
.fact_text {
	margin-top: 0.63rem; }
.progress {
	margin-top: 0.5rem; }
.lead {
	margin-top: 0.61rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	color: var(--blue);
	font-family: Coil;
	font-weight: 300; }
.illustration {
	position: relative;
	height: 23.66rem;
	width: 100%;
	margin-bottom: 1.7rem;
	img {
		width: 19.94rem;
		position: absolute;
		top: 0;
		left: 0.2rem; } }
.footer_cover {
	margin-top: 2.21rem; }

.text_3 {
	width: 21rem; }
.socials {
	display: none; }
@media screen and ( min-width: 768px ) {
	.wrap {
		padding-top: 2.4rem; }
	.img {
		position: relative;
		left: 0;
		top: 0;
		height: 0;
		img {
			top: -25.6rem;
			left: 26.4rem;
			width: 55rem; } }
	.title {
		width: 48rem; }
	.cover {
		height: calc(100vh - 2.4rem);
		min-height: 46.88rem;
		overflow: hidden; }
	.text {
		margin-top: 1.9rem;
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 28.5rem; }
	.socials {
		margin-top: 3.5rem;
		display: block; }
	.info {
		margin-top: 11.5rem;
		h2 {
			max-width: 41.28rem;
			margin-bottom: 0.93rem;
			&:nth-child(1) {
				max-width: 53.28rem;
				margin-bottom: 0.63rem; } }
		p:nth-child(1) {
			span {
				display: none; } }
		&_2 {
			margin-top: 7.68rem;
			h2 {
				max-width: 31.3rem;
				margin-bottom: 0.83rem;
				width: 100%; } } }
	.text {
		&_2 {
			width: 43.5rem; }
		&_3 {
			width: 39rem; }
		&_4 {
			width: 51rem; }
		&_5 {
			width: 51rem; } }
	.fact {
		margin-top: 4.59rem;
		margin-left: 8.94rem;
		margin-bottom: 8.79rem;
		width: 31rem; }
	.fact_number {
		font-size: 4.06rem; }
	.fact_text {
		margin-top: 1.13rem; }
	.progress {
		margin-top: -3.3rem; }
	.lead {
		margin-top: 1.01rem;
		font-size: 2.31rem;
		width: 53.00rem;
		line-height: 2.94rem;
		font-family: 'Exo 2', sans-serif; }
	.illustration {
		margin-bottom: 10.1rem;
		margin-top: 0;
		height: 26.66rem;
		img {
			transform: rotate(90deg);
			width: 29.06rem;
			left: 1.4rem; } }
	.footer_cover {
		margin-top: 2.01rem; }
	.faq {
		overflow: hidden;
 }		// margin-top: -2.4rem
	.cards {
		width: 35.69rem; } }
