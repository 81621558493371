
.wrap {
	position: relative;
	&_active {
		.item {
			&_1 {
				.fact_svg {
					svg {
						opacity: 0.4; } } }

			&_2 {
				bottom: -9.23rem;
				.fact_body {
					// transform: translateY(0)
					opacity: 1; } } } } }
.items {
	position: relative;
	width: 100%; }
.item {
	width: 100%;
	&_1 {
		.fact_body {
			position: absolute;
			height: 4.13rem;
			top: 0;
			left: 0;
			display: inline; }
		.fact_number {
			display: inline;
			font-size: 1rem;
			line-height: 1.38rem; }
		.fact_text {
			display: inline; }
		.fact_svg {
			margin-top: 4.76rem;
			svg {

				transition: all ease 1s;
				* {
					fill: var(--blue);
					&:last-child {

						stroke: var(--blue); } } } } }
	&_2 {
		position: absolute;
		bottom: -8.15rem;
		left: 0;
		// margin-top: -0.75rem
		transition: all ease 1s;
		.fact_body {
			margin-top: 0.25rem;
			// transform: translateY(2rem)
			opacity: 0; }
		.fact_number {
			letter-spacing: 0.05em; } } }
.fact {
	padding-top: 0.1rem;
	position: relative; }

.fact_number {
	font-size: 2.19rem;
	line-height: 1.44rem;
	font-weight: 600;
	color: var(--blue); }
.fact_text {
	margin-top: 0.5rem;
	width: 100%; }

.fact_body {
	padding-top: 0.2rem;
	transition: all ease 0.5s;
	transition-delay: 0.5s; }
.fact_svg {
	width: 20.06rem;
	svg {
		width: 100%;
		height: auto; } }

@media screen and ( min-width: 768px ) {

	.wrap {
		margin-left: 5.19rem;
		// height: 100%
		height: 37.06rem;
		width: 63.1rem;
		&_active {
			.item {
				&_2 {
					margin-top: 0;
					bottom: -12.1rem; } } } }
	.fact {
		display: flex;
		justify-content: space-between; }
	.fact_number {
		margin-top: 3.7rem;
		position: relative;
		font-size: 4.06rem;
		line-height: 2.69rem;
		font-family: Coil;
		letter-spacing: 0.05em;
		&::before {
			position: absolute;
			content: '~';
			font: inherit;
			color: inherit;
			top: 0;
			left: -2.7rem; } }
	.fact_body {
		width: 22.88rem; }
	.fact_text {
		margin-top: 1.2rem;
		font-size: 1.44rem;
		line-height: 2.06rem; }
	.fact_svg {
		width: 35.00rem; }
	.item {
		width: 100%;
		&_1 {
			.fact_body {
				position: static;
				display: flex;
				flex-direction: column; }
			.fact_number {
				margin-top: 4.8rem;
				font-size: 4.06rem;
				line-height: 2.69rem; }
			.fact_svg {
				margin-top: 2.13rem; } }
		&_2 {
			// position: static
			margin-top: 0;
			bottom: -9.3rem;
			.fact {
				flex-direction: row-reverse; }
			.fact_number {
				margin-top: 0;
				&::before {
					display: none; } }
			.fact_text {
				display: flex;
				justify-content: space-between;
				width: 47rem;
				p {
					width: 21rem; }
				p+p {
					margin-top: 0;
					margin-left: 1rem;
					width: 19.2rem;
					color: var(--blue); } } } } }
