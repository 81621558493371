.wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--white);
	z-index: 1000000;
	transition: transform ease 1s;
	padding: 2.19rem 1.94rem 2.86rem 2.19rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	font-family: 'Coil', sans-serif;
	&_hide {
		transform: translateY(-120%); } }
.bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	pointer-events: none;
	img {
		position: absolute;
		left: 0;
		bottom: 0; } }

.header {
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%; }
.logos {
	margin-top: 0.3rem;
	flex-shrink: 0; }
.logo_info {
	width: 7.81rem;
	height: 0.69rem; }
.logo_hiv {
	width: 9.25rem;
	height: 4.31rem;
	margin-top: 0.93rem;
	margin-left: -0.73rem; }
.label {
	margin-top: 0.4rem;
	width: 2.56rem;
	height: 1.31rem;
	border-radius: 0.19rem;
	background: #E11D2B;
	color: var(--white);
	font-size: 1rem;
	line-height: 1.31rem;
	display: flex;
	align-items: center;
	justify-content: center; }
.body {
	margin-top: 5.66rem; }
.title {
	font-weight: bold;
	font-size: 2.88rem;
	line-height: 3.19rem; }
.text {
	margin-top: 0.31rem;
	font-size: 1.25rem;
	line-height: 1.63rem;
	width: 18.19rem;
	p+p {
		margin-top: 1rem; } }
.scroll {
	margin: auto auto 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.25rem;
	line-height: 1.63rem;
	span {
		font-size: 1rem; }
	img {
		margin-top: 0.69rem;
		height: 0.56rem;
		width: 1.13rem;
		animation: fade_move_down 2s ease-in-out infinite; } }
.langs {
	position: absolute;
	right: 2.4rem;
	top: 4.6rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: normal;
	z-index: 1000; }
.lang {
	text-decoration: none;
	color: #C4C4C4;
	margin-bottom: 0.1rem;
	&_active {
		color: var(--red);
		text-decoration: underline; } }
@keyframes fade_move_down {
	0% {
		transform: translate(0, -100%);
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		transform: translate(0, 100%);
		opacity: 0; } }

@media screen and ( min-width: 768px ) {
	.wrap {
		align-items: center;
		justify-content: center; }
	.header {
		align-items: center;
		justify-content: center;
		max-width: 90.00rem;
		margin: 0 auto; }
	.logos {
		display: flex; }
	.body {
		margin-top: 4.96rem;
		text-align: center; }
	.scroll {
		margin: 6.5rem auto 0; }
	.logo_info {
		margin-top: 4.2rem;
		width: 10.19rem;
		height: 0.88rem;
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0; } }
	.logo_hiv {
		width: 12.06rem;
		height: 5.63rem;
		margin-top: 0;
		margin-left: 1.31rem; }
	.label {
		margin-top: 2.8rem;
		margin-left: 2.3rem;
		width: 3.75rem;
		height: 1.94rem;
		border-radius: 0.31rem;
		background: #E11D2B;
		color: var(--white);
		font-size: 1.44rem;
		line-height: 1.94rem;
		display: flex;
		align-items: center;
		justify-content: center; }
	.text {
		width: 26.19rem; }
	.langs {
		right: 4.6rem;
		top: 3.2rem;
		display: flex;
		flex-direction: row; }
	.lang {
		margin-left: 0.7rem; } }
