.wrap {
	position: relative;
	width: 100%;
	border: 0.06rem solid var(--blue);
	border-radius: 0.2rem;
	&::after {
		position: absolute;
		content: '';
		right: 1.88rem;
		top: -0.06rem;
		width: 2.44rem;
		height: 0.06rem;
		background: var(--white);
		z-index: 1; } }
.body {

	padding-top: 1.13rem;
	padding-left: 1.69rem; }
.img {
	position: absolute;
	top: -2rem;
	right: 1.88rem;
	width: 2.44rem;
	height: 31.63rem;
	z-index: 10; }
.title {
	width: 10.44rem; }

.label {
	margin-top: 0.2rem;
	position: relative;
	display: block;
	font: inherit;
	color: var(--white);
	&::after {
		position: absolute;
		content: '';
		top: 50%;
		left: -0.28rem;
		transform: translateY(-39.6%);
		width: 9.13rem;
		height: 1.56rem;
		background: var(--blue);
		z-index: -1; } }
.props {
	margin-top: 4.75rem;
	width: 12.63rem; }
.prop {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.54rem;
	span:first-child {
		font-weight: bold; } }

.footer {
	padding: 2.16rem 3.50rem 1.94rem 1.69rem;
	margin-top: 2.38rem;
	background: #000;

	color: var(--white);
	background: var(--blue); }
.text {
	font-weight: 600;
	margin-bottom: 0.59rem; }

.link {
	margin-bottom: 0.56rem;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	color: var(--white);
	opacity: 0.7;
	// text-decoration-skip-ink: none
	// text-decoration-thickness: 0.1rem
	text-decoration: none; }
.preicon {
	width: 0.56rem;
	margin-right: 0.5rem;
	flex-shrink: 0; }
.posticon {
	position: relative;
	display: inline-block;
	top: 0.07rem;
	left: -0.05rem;
	width: 1.08rem; }
@media screen and ( min-width: 768px ) {
	.wrap {
		border-radius: 0.75rem;
		width: 82.50rem;
		left: 50%;
		border: 0.13rem solid var(--blue);
		// overflow: hidden
		transform: translate(-50%);
		&::after {
			display: none; }
		&::before {
			position: absolute;
			display: block;
			content: '';
			bottom: -0.13rem;
			left: -0.13rem;
			height: 10.06rem;
			width: calc(100% + 0.26rem);
			background: var(--blue);
			border-radius: 0 0 0.75rem 0.75rem; } }
	.body {
		padding: 2.75rem 7.28rem 0 4.89rem;
		display: flex;
		justify-content: space-between; }
	.title {
		font-size: 2.31rem;
		line-height: 2.94rem;
		width: 26.69rem;
		font-weight: 300; }
	.label {
		&::after {
			width: 17.25rem;
			height: 3.00rem;
			left: -0.88rem;
			transform: translateY(-42.6%); } }
	.props {
		margin-top: 0.6rem;
		width: 21.25rem;
		font-size: 1.44rem;
		line-height: 2.06rem;
		br {
			display: none; } }
	.prop {
		margin-bottom: 1.04rem; }
	.img {
		top: 17.39rem;
		left: 3.19rem;
		width: 48.69rem;
		height: 6.38rem; }
	.footer {
		background: none;
		margin-top: 1.78rem;
		padding: 1.76rem 3.5rem 1.44rem 4.89rem;
		display: flex;
		position: relative;
		z-index: 100; }
	.text {
		font-size: 2.31rem;
		line-height: 2.94rem;
		font-weight: 300;
		flex-shrink: 0; }
	.links {
		margin-top: 0.5rem;
		margin-left: 20.9rem; }
	.link {
		margin-bottom: 1.36rem;
		font-size: 1.13rem;
		font-weight: 300;
		line-height: 1.38rem; }
	.posticon {
		top: 0.27rem;
		width: 1.38rem; } }
